export default {
  day1: {
    full: 'poniedziałek',
    short: 'pon',
    shortPart1: 'pon',
    shortPart2: 'iedziałek'
  },
  day2: {
    full: 'wtorek',
    short: 'wto',
    shortPart1: 'wto',
    shortPart2: 'rek'
  },
  day3: {
    full: 'środa',
    short: 'śro',
    shortPart1: 'śro',
    shortPart2: 'da'
  },
  day4: {
    full: 'czwartek',
    short: 'czw',
    shortPart1: 'czw',
    shortPart2: 'artek'
  },
  day5: {
    full: 'piątek',
    short: 'pią',
    shortPart1: 'pią',
    shortPart2: 'tek'
  },
  day6: {
    full: 'sobota',
    short: 'sob',
    shortPart1: 'sob',
    shortPart2: 'ota'
  },
  day7: {
    full: 'niedziela',
    short: 'nie',
    shortPart1: 'nie',
    shortPart2: 'dziela'
  }
};
