export default {
  0: {
    full: 'styczeń',
    short: 'sty'
  },
  1: {
    full: 'luty',
    short: 'lut'
  },
  2: {
    full: 'marzec',
    short: 'mar'
  },
  3: {
    full: 'kwiecień',
    short: 'kwie'
  },
  4: {
    full: 'maj',
    short: 'maj'
  },
  5: {
    full: 'czerwiec',
    short: 'czer'
  },
  6: {
    full: 'lipiec',
    short: 'lip'
  },
  7: {
    full: 'siepień',
    short: 'sier'
  },
  8: {
    full: 'wrzesień',
    short: 'wrze'
  },
  9: {
    full: 'październik',
    short: 'paź'
  },
  10: {
    full: 'listopad',
    short: 'list'
  },
  11: {
    full: 'grudzień',
    short: 'gru'
  }
};
