/**
 * Core
 */
import Vue from 'vue';
import Router from 'vue-router';
import RedirectUtils from './utils.js';

/**
 * Views
 */
import Dashboard from './../views/Dashboard.vue';
import Errors from './../views/Errors.vue';
import Login from './../views/Login.vue';
import Logout from './../views/Logout.vue';
import Splashscreen from './../views/SplashScreen.vue';
import Users from './../views/Users.vue';
import Machines from './../views/Machines.vue';
import MachinesGroups from './../views/MachinesGroups.vue';
import Services from './../views/Services.vue';
import ServiceGroups from './../views/ServiceGroups.vue';
import Prices from './../views/Prices.vue';
import OrdersWrapper from './../views/OrdersWrapper.vue';
import OrdersController from '@/views/OrdersController.vue';
import Strands from './../views/Strands.vue';
import Settings from './../views/Settings.vue'
import Schedule2 from './../views/Schedule2.vue';
import Schedule3 from './../views/Schedule3.vue';
import WorkTimeLog from './../views/WorkTimeLog.vue';
import EmbroideryTypes from './../views/EmbroideryTypes.vue';
import EmbroideryPositions from './../views/EmbroideryPositions.vue';
import WorkingHours from './../views/WorkingHours.vue';
import Contractors from './../views/Contractors.vue';
import Statistics from './../views/Statistics.vue';

/**
 * Subviews
 */
import NoPermissions from './../views/NoPermissions.vue';
import UserForm from './../components/forms/UserForm.vue';
import MachineForm from './../components/forms/MachineForm.vue';
import MachinesGroupForm from './../components/forms/MachinesGroupForm.vue';
import ServiceForm from './../components/forms/ServiceForm.vue';
import ServiceGroupForm from './../components/forms/ServiceGroupForm.vue';
import PriceForm from './../components/forms/PriceForm.vue';
import OrderForm from './../components/forms/OrderForm.vue';
import OrderControllerForm from './../components/forms/OrderControllerForm.vue';
import OrderOperatorForm from './../components/forms/OrderOperatorForm.vue';
import OrderControllerFormPreview from '../components/forms/OrderControllerFormPreview.vue';
import StrandForm from './../components/forms/StrandForm.vue';
import WorkTimeEntryForm from './../components/forms/WorkTimeEntryForm.vue';
import EmbroideryTypeForm from './../components/forms/EmbroideryTypeForm.vue';
import EmbroideryPositionForm from './../components/forms/EmbroideryPositionForm.vue';
import WorkingHoursForm from './../components/forms/WorkingHoursForm.vue';
import ContractorForm from './../components/forms/ContractorForm.vue';

/**
 * Helpers
 */

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  linkActiveClass: 'active',
  linkExactActiveClass: 'exact-active',
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: '/logowanie',
      component: Login,
      name: 'Logowanie',
      meta: {
        layout: 'guest'
      }
    },
    {
      path: '/wyloguj',
      component: Logout,
      name: 'Wyloguj',
    },
    {
      path: '/wczytywanie',
      component: Splashscreen,
      name: 'Splashscreen'
    },
    {
      path: '/',
      redirect: '/kokpit'
    },
    {
      path: '/kokpit',
      component: Dashboard,
      name: 'Kokpit',
      beforeEnter: (to, from, next) => {
        RedirectUtils.permissionsCheck('dashboard', next);
      }
    },
    {
      path: '/uzytkownicy',
      component: Users,
      name: 'Użytkownicy',
      beforeEnter: (to, from, next) => {
        RedirectUtils.permissionsCheck('users', next);
      }
    },
    {
      path: '/uzytkownicy/nowy',
      component: UserForm,
      name: 'Dodawanie Użytkownika',
      beforeEnter: (to, from, next) => {
        RedirectUtils.permissionsCheck('users', next);
      }
    },
    {
      path: '/uzytkownicy/edycja/:id',
      component: UserForm,
      name: 'Edycja użytkownika',
      beforeEnter: (to, from, next) => {
        RedirectUtils.permissionsCheck('users', next);
      }
    },
    {
      path: '/maszyny',
      component: Machines,
      name: 'Maszyny',
      beforeEnter: (to, from, next) => {
        RedirectUtils.permissionsCheck('machines', next);
      }
    },
    {
      path: '/maszyny/nowy',
      component: MachineForm,
      name: 'Dodawanie maszyny',
      beforeEnter: (to, from, next) => {
        RedirectUtils.permissionsCheck('machines', next);
      }
    },
    {
      path: '/maszyny/edycja/:id',
      component: MachineForm,
      name: 'Edycja maszyny',
      beforeEnter: (to, from, next) => {
        RedirectUtils.permissionsCheck('machines', next);
      }
    },
    {
      path: '/maszyny/grupy',
      component: MachinesGroups,
      name: 'Grupy maszyn',
      beforeEnter: (to, from, next) => {
        RedirectUtils.permissionsCheck('machines', next);
      }
    },
    {
      path: '/maszyny/grupy/nowy',
      component: MachinesGroupForm,
      name: 'Dodawanie grupy maszyn',
      beforeEnter: (to, from, next) => {
        RedirectUtils.permissionsCheck('machines', next);
      }
    },
    {
      path: '/maszyny/grupy/edycja/:id',
      component: MachinesGroupForm,
      name: 'Edycja grupy maszyn',
      beforeEnter: (to, from, next) => {
        RedirectUtils.permissionsCheck('machines', next);
      }
    },
    {
      path: '/zdobienia',
      component: Services,
      name: 'Zdobienia',
      beforeEnter: (to, from, next) => {
        RedirectUtils.permissionsCheck('services', next);
      }
    },
    {
      path: '/zdobienia/nowy',
      component: ServiceForm,
      name: 'Dodawanie zdobienia',
      beforeEnter: (to, from, next) => {
        RedirectUtils.permissionsCheck('services', next);
      }
    },
    {
      path: '/zdobienia/edycja/:id',
      component: ServiceForm,
      name: 'Edycja zdobienia',
      beforeEnter: (to, from, next) => {
        RedirectUtils.permissionsCheck('services', next);
      }
    },
    {
      path: '/zdobienia/grupy',
      component: ServiceGroups,
      beforeEnter: (to, from, next) => {
        RedirectUtils.permissionsCheck('services', next);
      }
    },
    {
      path: '/zdobienia/grupy/nowa',
      component: ServiceGroupForm,
      name: 'Dodawanie grupy zdobień',
      beforeEnter: (to, from, next) => {
        RedirectUtils.permissionsCheck('services', next);
      }
    },
    {
      path: '/zdobienia/grupy/edycja/:id',
      component: ServiceGroupForm,
      name: 'Edycja grupy zdobień',
      beforeEnter: (to, from, next) => {
        RedirectUtils.permissionsCheck('services', next);
      }
    },
    {
      path: '/cenniki/zdobienia',
      component: Prices,
      name: 'Cennik zdobień',
      meta: {
        type: 'services'
      },
      beforeEnter: (to, from, next) => {
        RedirectUtils.permissionsCheck('prices', next);
      }
    },
    {
      path: '/cenniki/zdobienia/nowy',
      component: PriceForm,
      meta: {
        type: 'services'
      },
      beforeEnter: (to, from, next) => {
        RedirectUtils.permissionsCheck('prices', next);
      }
    },
    {
      path: '/cenniki/zdobienia/edycja/:id',
      component: PriceForm,
      meta: {
        type: 'services'
      },
      beforeEnter: (to, from, next) => {
        RedirectUtils.permissionsCheck('prices', next);
      }
    },
    {
      path: '/cenniki/setup',
      component: Prices,
      name: 'Cennik setups',
      meta: {
        type: 'setups'
      },
      beforeEnter: (to, from, next) => {
        RedirectUtils.permissionsCheck('prices', next);
      }
    },
    {
      path: '/cenniki/setup/nowy',
      component: PriceForm,
      meta: {
        type: 'setups'
      },
      beforeEnter: (to, from, next) => {
        RedirectUtils.permissionsCheck('prices', next);
      }
    },
    {
      path: '/cenniki/setup/edycja/:id',
      component: PriceForm,
      meta: {
        type: 'setups'
      },
      beforeEnter: (to, from, next) => {
        RedirectUtils.permissionsCheck('prices', next);
      }
    },
    {
      path: '/wykonawcy',
      component: Contractors,
      name: 'Wykonawcy',
      beforeEnter: (to, from, next) => {
        RedirectUtils.permissionsCheck('contractors', next);
      }
    },
    {
      path: '/wykonawcy/nowy',
      component: ContractorForm,
      name: 'Dodawanie wykonawcy',
      beforeEnter: (to, from, next) => {
        RedirectUtils.permissionsCheck('contractors', next);
      }
    },
    {
      path: '/wykonawcy/edycja/:id',
      component: ContractorForm,
      name: 'Edycja wykonawcy',
      beforeEnter: (to, from, next) => {
        RedirectUtils.permissionsCheck('contractors', next);
      }
    },
    {
      path: '/zamowienia/',
      component: OrdersWrapper,
      name: 'Zamówienia',
      beforeEnter: (to, from, next) => {
        RedirectUtils.permissionsCheck('orders', next);
      }
    },
    {
      path: '/zamowienia/nowy',
      component: OrderForm,
      name: 'Dodawanie zamówienia',
      beforeEnter: (to, from, next) => {
        RedirectUtils.permissionsCheck('orders', next);
      }
    },
    {
      path: '/zamowienia/edycja/:id',
      component: OrderForm,
      name: 'Edycja zamówienia',
      beforeEnter: (to, from, next) => {
        RedirectUtils.permissionsCheck('orders', next);
      }
    },
    {
      path: '/zamowienia/podglad/:id',
      component: OrderControllerFormPreview,
      name: 'Podgląd zamówienia',
      beforeEnter: (to, from, next) => {
        RedirectUtils.permissionsCheck('orders', next);
      }
    },
    {
      path: '/produkty-do-skontrolowania/',
      component: OrdersController,
      name: 'Produkty do skontrolowania',
      beforeEnter: (to, from, next) => {
        RedirectUtils.permissionsCheck('orders', next);
      }
    },
    {
      path: '/produkty-do-skontrolowania/edycja/:id',
      component: OrderControllerForm,
      name: 'Produkt do skontrolowania',
      beforeEnter: (to, from, next) => {
        RedirectUtils.permissionsCheck('orders', next);
      }
    },
    {
      path: '/zamowienia/maszyny/edycja/:id',
      component: OrderOperatorForm,
      name: 'Edycja realizacji',
      beforeEnter: (to, from, next) => {
        RedirectUtils.permissionsCheck('orders', next);
      }
    },
    {
      path: '/harmonogram/',
      component: Schedule2,
      name: 'Harmonogram v2',
      beforeEnter: (to, from, next) => {
        RedirectUtils.permissionsCheck('schedules', next);
      }
    },
    {
      path: '/harmonogram-nowy/',
      component: Schedule3,
      name: 'Harmonogram v3',
      beforeEnter: (to, from, next) => {
        RedirectUtils.permissionsCheck('schedules', next);
      }
    },
    {
      path: '/czas-pracy/',
      component: WorkTimeLog,
      name: 'Czas pracy',
      beforeEnter: (to, from, next) => {
        RedirectUtils.permissionsCheck('working_times', next);
      }
    },
    {
      path: '/czas-pracy/nowy',
      component: WorkTimeEntryForm,
      name: 'Dodawanie czasu pracy',
      beforeEnter: (to, from, next) => {
        RedirectUtils.permissionsCheck('working_times', next);
      }
    },
    {
      path: '/czas-pracy/edycja/:id',
      component: WorkTimeEntryForm,
      name: 'Edycja czasu pracy',
      beforeEnter: (to, from, next) => {
        RedirectUtils.permissionsCheck('working_times', next);
      }
    },
    {
      path: '/ustawienia',
      component: Settings,
      beforeEnter: (to, from, next) => {
        RedirectUtils.permissionsCheck('strands', next);
      }
    },
    {
      path: '/ustawienia/nici',
      component: Strands,
      name: 'Nici',
      beforeEnter: (to, from, next) => {
        RedirectUtils.permissionsCheck('strands', next);
      }
    },
    {
      path: '/ustawienia/nici/nowy',
      component: StrandForm,
      name: 'Dodawanie nici',
      beforeEnter: (to, from, next) => {
        RedirectUtils.permissionsCheck('strands', next);
      }
    },
    {
      path: '/ustawienia/nici/edycja/:id',
      component: StrandForm,
      name: 'Edycja nici',
      beforeEnter: (to, from, next) => {
        RedirectUtils.permissionsCheck('strands', next);
      }
    },
    {
      path: '/ustawienia/rodzaje-haftow',
      component: EmbroideryTypes,
      name: 'Rodzaje haftów',
      beforeEnter: (to, from, next) => {
        RedirectUtils.permissionsCheck('embroidery', next);
      }
    },
    {
      path: '/ustawienia/rodzaje-haftow/nowy',
      component: EmbroideryTypeForm,
      name: 'Dodawanie rodzaju haftów',
      beforeEnter: (to, from, next) => {
        RedirectUtils.permissionsCheck('embroidery', next);
      }
    },
    {
      path: '/ustawienia/rodzaje-haftow/edycja/:id',
      component: EmbroideryTypeForm,
      name: 'Edycja rodzaju haftów',
      beforeEnter: (to, from, next) => {
        RedirectUtils.permissionsCheck('embroidery', next);
      }
    },
    {
      path: '/ustawienia/pozycje-dekoracji',
      component: EmbroideryPositions,
      name: 'Pozycje dekoracji',
      beforeEnter: (to, from, next) => {
        RedirectUtils.permissionsCheck('embroidery', next);
      }
    },
    {
      path: '/ustawienia/pozycje-dekoracji/nowy',
      component: EmbroideryPositionForm,
      name: 'Dodawanie pozycji dekoracji',
      beforeEnter: (to, from, next) => {
        RedirectUtils.permissionsCheck('embroidery', next);
      }
    },
    {
      path: '/ustawienia/pozycje-dekoracji/edycja/:id',
      component: EmbroideryPositionForm,
      name: 'Edycja pozycji dekoracji',
      beforeEnter: (to, from, next) => {
        RedirectUtils.permissionsCheck('embroidery', next);
      }
    },
    {
      path: '/operatorzy-i-maszyny',
      component: WorkingHours,
      name: 'Operatorzy i maszyny',
      beforeEnter: (to, from, next) => {
        RedirectUtils.permissionsCheck('working_hours', next);
      }
    },
    {
      path: '/operatorzy-i-maszyny/nowy',
      component: WorkingHoursForm,
      name: 'Dodawanie przypisania',
      beforeEnter: (to, from, next) => {
        RedirectUtils.permissionsCheck('working_hours', next);
      }
    },
    {
      path: '/operatorzy-i-maszyny/edycja/:id',
      component: WorkingHoursForm,
      name: 'Edycja przypisania',
      beforeEnter: (to, from, next) => {
        RedirectUtils.permissionsCheck('working_hours', next);
      }
    },
    {
      path: '/statystyki',
      component: Statistics,
      name: 'Statystyki',
      beforeEnter: (to, from, next) => {
        RedirectUtils.permissionsCheck('stats', next);
      }
    },
    {
      path: '/404',
      component: Errors
    },
    {
      path: '/brak-uprawnien',
      component: NoPermissions
    },
    {
      path: '*',
      redirect: '/404'
    }
  ]
});

router.beforeEach((to, from, next) => {
  let notLoggedInRoutes = [
    'Logowanie',
    'Wyloguj'
  ];

  if (notLoggedInRoutes.indexOf(to.name) !== -1) {
    let splashscreenHtml = document.querySelector('#splashscreen');

    if (splashscreenHtml) {
      splashscreenHtml.parentNode.removeChild(splashscreenHtml);
    }

    next();
    return;
  }

  if (!window.crmApp) {
    setTimeout(() => {
      next({
        name: 'Splashscreen',
        params: {
          nextRoute: to.path
        }
      });

      let splashscreenHtml = document.querySelector('#splashscreen');

      if (splashscreenHtml) {
        splashscreenHtml.parentNode.removeChild(splashscreenHtml);
      }
    }, 0);

    return;
  }

  next();
});

export default router;
