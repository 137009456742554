<template>
  <div :class="dayClassList">
    <template v-if="currentDay.isCurrentMonth">
      <div class="schedule-day-header py-2 px-2 border-bottom bg-light">
        <div class="col-name">
          <span>{{ currentDay.date }}</span>
          <!-- TODO: kolorowanie statusów -->
        </div>
        <div class="col-quantity  text-center">
          Ilość (szt.)
        </div>
        <div
          v-for="group of machinesGroups"
          :key="`machine-group-${currentDay.date}-${group.id}`"
          class="col-machine text-center">
          {{ group.name }}<br>(min)
        </div>
      </div>
      <div class="schedule-day-body px-2 ">
        <div
          v-for="(order, orderIndex) of ordersForDay"
          :key="`order-${currentDay.date}-${orderIndex}`"
          :class="{
            'schedule-day-entry py-2': true,
            'border-top': orderIndex > 0
          }">
          <div
            class="col-name text-left"
            :title="order.order_name">
            {{ order.order_id }} {{ order.order_name }}
          </div>
          <div class="col-quantity  text-right">
            {{ order.planned_quantity }}
            <!-- ilość sztuk zdobień, przyjmijmy że zaplanowanych w tym dniu wszystkich zdobień z tego zamówienia -->
          </div>
          <div
            v-for="group of machinesGroups"
            :key="`machine-plan-${currentDay.date}-${group.id}`"
            class="col-machine text-right">
            {{ getPlannedCountInGroup(order, group.id) }}
            <!-- wyświetlamy sumę czasu wykonania wszystkich zaplanowanych na ten dzień zdobień z tego zamówienia w danej grupie maszyn -->
          </div>
        </div>
      </div>
      <div class="schedule-day-footer px-2 pt-2 pb-3 border-top text-primary">
        <div class="col-name">
          RAZEM (szt.):
        </div>
        <div class="col-quantity text-right">
          {{ totalQuantity }}
        </div>
        <div
          v-for="group of machinesGroups"
          :key="`machine-plan-${currentDay.date}-${group.id}`"
          class="col-machine text-right">
          {{ totalExecutedTimeInGroups[group.id] || 0 }}
        </div>
      </div>
    </template>
  </div>
</template>
<script>

export default {
  name: 'schedule-day',
  props: {
    currentDay: {
      required: true,
      type: Object
    },
    machines: {
      required: true,
      type: Array
    },
    machinesGroups: {
      required: true,
      type: Array
    },
    scheduleData: {
      default: () => [],
      type: Array
    }
  },
  computed: {
    dayClassList () {
      let classList = ['schedule-day', 'border', 'border-2'];

      if (!this.currentDay.isCurrentMonth) {
        classList.push('different-month');
      }

      return classList.join(' ');
    },
    ordersForDay () {
      let currentDaySchedule = this.scheduleData.find(item => item.date === this.currentDay.date);
      if (!currentDaySchedule) {
        return [];
      }

      // TODO: docelowo to powinno być zebrane po stronie API chyba ale doustalmy najpierw
      let allServices = currentDaySchedule.order_services;

      let groupedServices = allServices.reduce((acc, current) => {
        const existingOrder = acc.find(service => service.order_id === current.order_id);

        // TODO: w macine_group.services można by też zwracać sample_preparation_time i to by chyba ułatwiło, ale to po ewentualnych zmianach w API

        if (existingOrder) {
          existingOrder.planned_quantity += current.planned_quantity;

          current.machine_groups.forEach(machineGroup => {
            const existingMachineGroup = existingOrder.machine_groups.find(m => m.id === machineGroup.id);

            if (existingMachineGroup) {
              existingMachineGroup.execution_time += this.getServiceExecutionTime(current, machineGroup.id);
              existingMachineGroup.planned_quantity += machineGroup.planned_quantity;
            } else {
              existingOrder.machine_groups.push({
                id: machineGroup.id,
                name: machineGroup.name,
                planned_quantity: machineGroup.planned_quantity,
                execution_time: this.getServiceExecutionTime(current, machineGroup.id)
              });
            }
          });
        } else {
          let machineGroups = current.machine_groups.map(machineGroup => ({
            id: machineGroup.id,
            name: machineGroup.name,
            planned_quantity: machineGroup.planned_quantity,
            execution_time: this.getServiceExecutionTime(current, machineGroup.id)
          }));

          acc.push({
            order_id: current.order_id,
            order_name: current.order_name,
            planned_quantity: current.planned_quantity,
            machine_groups: machineGroups,
          });
        }

        return acc;
      }, []);

      return groupedServices;
    },
    totalQuantity () {
      return this.ordersForDay.reduce((sum, item) => sum + item.planned_quantity, 0);
    },
    totalExecutedTimeInGroups () {
      return this.ordersForDay.reduce((acc, order) => {
        order.machine_groups.forEach(machineGroup => {
          if (acc[machineGroup.id]) {
            acc[machineGroup.id] += machineGroup.execution_time;
          } else {
            acc[machineGroup.id] = machineGroup.execution_time;
          }
        });

        return acc;
      }, {});
    }
  },
  methods: {
    getPlannedCountInGroup (service, groupID) {
      let machineGroup = service.machine_groups.find(item => item.id === groupID);
      if (!machineGroup) {
        return 0;
      }

      return machineGroup.execution_time;
    },
    getServiceExecutionTime (service, machineGroupID) {
      let samplePreparationTime = service.sample_preparation_time;
      let allExecutionsTime = 0;

      for (let i = 0; i < service.items.length; i++) {
        let planItem = service.items[i];
        if (planItem.machine_group_id !== machineGroupID) {
          // eslint-disable-next-line no-continue
          continue;
        }

        let machine = this.machines.find(item => item.id === planItem.machine_id);
        if (!machine) {
          console.warn(`machine with ID ${planItem.machine_id} not found`);
          // eslint-disable-next-line no-continue
          continue;
        }

        allExecutionsTime += (samplePreparationTime / machine.heads_number) * planItem.planned_quantity;
      }

      return allExecutionsTime;
    }
  }
}
</script>
