import MonthsNames from '@/data/months-names.js';
import DaysNames from '@/data/days-names.js';

export default class CalendarUtils {
  static getIsSameDay (date1, date2) {
    if (!date1 || !date2) {
      return false;
    }
    if (date1.getDate() !== date2.getDate()) {
      return false;
    }
    if (date1.getMonth() !== date2.getMonth()) {
      return false;
    }
    if (date1.getFullYear() !== date2.getFullYear()) {
      return false;
    }

    return true;
  }

  static getIsDateInRange (dateToCheck, datFrom, dateTo) {
    let itemDate = dateToCheck.getTime();

    if (!datFrom && !dateTo) {
      return true;
    }

    if (!datFrom) {
      return (itemDate <= dateTo.getTime());
    }

    if (!dateTo) {
      return (itemDate >= datFrom.getTime());
    }

    return (itemDate >= datFrom.getTime() && itemDate <= dateTo.getTime());
  }

  static getIsDayBeforeDate (date1, date2) {
    return new Date(new Date(date1).toDateString()) < new Date(new Date(date2).toDateString());
  }

  static formatDateString (dateInput, noDateTxt = '-') {
    if (dateInput === null) {
      return noDateTxt;
    }
    let dateToFormat = new Date(dateInput);

    return [
      dateToFormat.getFullYear(),
      dateToFormat.getMonth() + 1,
      dateToFormat.getDate()
    ]
      .map(n => n < 10 ? '0' + n : n)
      .join('-');
  }

  static formatDateTimeString (dateInput, noDateTxt = '-') {
    if (!dateInput) {
      return noDateTxt;
    }

    let newDate = new Date(dateInput);

    let datePart = [
      newDate.getFullYear(),
      newDate.getMonth() + 1,
      newDate.getDate()
    ]
      .map(n => n < 10 ? '0' + n : n)
      .join('-');

    let timePart = [
      newDate.getHours(),
      newDate.getMinutes()
    ]
      .map(n => n < 10 ? '0' + n : n)
      .join(':');

    return `${datePart} ${timePart}`;
  }

  static formatTimeString (input, noTimeTxt = '-') {
    if (input === null) {
      return noTimeTxt;
    }

    return [input.getHours(), input.getMinutes()].map(n => n < 10 ? '0' + n : n).join(':');
  }

  /**
   * Returns first day visible in calendar "card"
   */
  static getStartDay (year, month) {
    let date = new Date(year, month - 1, 1, 12, 0, 0, 0);
    let dayNumber = date.getDay();

    if (dayNumber > 0) {
      date.setTime(date.getTime() - ((dayNumber - 1) * 24 * 60 * 60 * 1000));
    } else if (dayNumber === 0) {
      date.setTime(date.getTime() - (6 * 24 * 60 * 60 * 1000));
    }

    return this.formatDateString(date);
  }

  /**
   * Returns last day visible in calendar "card"
   */
  static getEndDay (year, month) {
    let dateTemp = new Date(year, month, 0, 12, 0, 0, 0);
    let date = new Date(year, month - 1, dateTemp.getDate(), 12, 0, 0, 0);
    let dayNumber = date.getDay();

    if (dayNumber > 0) {
      date.setTime(date.getTime() + ((7 - dayNumber) * 24 * 60 * 60 * 1000));
    }

    return this.formatDateString(date);
  }

  /**
   * Returns list of dates in a given period
   */
  static getDaysList (year, month) {
    let start = this.getStartDay(year, month);
    let end = this.getEndDay(year, month);
    let startArray = start.split('-').map(num => parseInt(num, 10));
    let endArray = end.split('-').map(num => parseInt(num, 10));
    let endDate = new Date(endArray[0], endArray[1] - 1, endArray[2], 0, 0, 0, 0);
    let today = new Date();
    let tempDate = new Date(startArray[0], startArray[1] - 1, startArray[2], 0, 0, 0);
    let days = [];

    while (tempDate.getTime() <= endDate.getTime()) {
      days.push({
        number: tempDate.getDate(),
        date: this.formatDateString(tempDate),
        dayName: this.getDayName(tempDate.getDay()),
        isCurrentMonth: tempDate.getMonth() + 1 === month,
        isToday: today.getFullYear() === tempDate.getFullYear() && today.getMonth() === tempDate.getMonth() && today.getDate() === tempDate.getDate()
      });
      tempDate.setDate(tempDate.getDate() + 1);
    }

    return days;
  }

  /**
   * Returns month name according to given month number
   */
  static getMonthName (monthNumber, short = false) {
    switch (monthNumber) {
      case 1: return short ? MonthsNames[0].short : MonthsNames[0].full;
      case 2: return short ? MonthsNames[1].short : MonthsNames[1].full;
      case 3: return short ? MonthsNames[2].short : MonthsNames[2].full;
      case 4: return short ? MonthsNames[3].short : MonthsNames[3].full;
      case 5: return short ? MonthsNames[4].short : MonthsNames[4].full;
      case 6: return short ? MonthsNames[5].short : MonthsNames[5].full;
      case 7: return short ? MonthsNames[6].short : MonthsNames[6].full;
      case 8: return short ? MonthsNames[7].short : MonthsNames[7].full;
      case 9: return short ? MonthsNames[8].short : MonthsNames[8].full;
      case 10: return short ? MonthsNames[9].short : MonthsNames[9].full;
      case 11: return short ? MonthsNames[10].short : MonthsNames[10].full;
      case 12: return short ? MonthsNames[11].short : MonthsNames[11].full;
      default: return short ? MonthsNames[0].short : MonthsNames[0].full;
    }
  }

  /**
   * Returns day name according to given day number
   */
  static getDayName (dayNumber, short = false) {
    switch (dayNumber) {
      case 1: return short ? DaysNames.day1.short : DaysNames.day1.full;
      case 2: return short ? DaysNames.day2.short : DaysNames.day2.full;
      case 3: return short ? DaysNames.day3.short : DaysNames.day3.full;
      case 4: return short ? DaysNames.day4.short : DaysNames.day4.full;
      case 5: return short ? DaysNames.day5.short : DaysNames.day5.full;
      case 6: return short ? DaysNames.day6.short : DaysNames.day6.full;
      case 0: return short ? DaysNames.day7.short : DaysNames.day7.full;
      default: return short ? DaysNames.day1.short : DaysNames.day1.full;
    }
  }
};
