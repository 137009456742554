<template>
  <d-container
    fluid
    class="main-content-container exact-height px-0 px-md-2 px-lg-4 pb-3 schedules-view">
    <!-- Page Header -->
    <d-row
      no-gutters
      class="page-header py-4">
      <d-col
        sm="12"
        md="12"
        lg="12"
        class="text-left mb-4 mb-sm-0">
        <span class="text-uppercase page-subtitle">Harmonogram</span>
        <h3 class="page-title">Harmonogram zamówień</h3>
      </d-col>
    </d-row>

    <div class="mb-3 page-filters d-flex align-items-center">
      <d-button
        outline
        theme="primary"
        @click.prevent="changeMonth('prev')"
        class="my-2"
        title="Poprzedni miesiąc"
        :disabled="!allDataLoaded">
        <i class="material-icons ml-1">keyboard_arrow_left</i>
      </d-button>

      <div class="my-2 mx-3"><span class="h4 my-auto" style="text-transform: capitalize;">{{ formattedMonthYear }}</span></div>

      <d-button
        outline
        theme="primary"
        @click.prevent="changeMonth('next')"
        class="my-2"
        title="Następny miesiąc"
        :disabled="!allDataLoaded">
        <i class="material-icons ml-1">keyboard_arrow_right</i>
      </d-button>

      <d-button
        theme="primary"
        @click.prevent="changeMonth()"
        class="ml-auto my-2"
        title="Pokaż aktualny miesiąc"
        :disabled="!allDataLoaded || ((filters.month === currentDate.month) && (filters.year === currentDate.year))">
        Pokaż aktualny miesiąc
      </d-button>
    </div>

    <d-card style="width: 100%; min-height: 80px;">
      <d-alert
        v-if="loadError"
        show
        theme="warning">
        Wczytywanie danych nie powiodło się.
        <a
          href="javascript:window.location.reload();"
          class="alert-link">
          Odśwież stronę
        </a>
        aby spróbować ponownie.
      </d-alert>
      <div
        v-else-if="!allDataLoaded"
        class="overlay-loading" >
        <div class="loading-spinner pb-3 pt-3 text-center">
          <span class="loading-spinner-content text-primary">
            Trwa ładowanie danych&hellip;
          </span>
        </div>
      </div>
      <p
        v-else-if="!scheduleDays.length"
        class="my-auto text-center">Brak elementów do wyświetlenia.
      </p>
      <div
        v-else
        class="schedule-days-wrapper mt-3 mb-3">
        <div
          v-for="index in 7"
          :key="index"
          class="schedule-day schedule-header font-weight-bold text-uppercase text-center py-2 border border-2">
          {{ (index === 7) ? getDayName(0) : getDayName(index) }}
        </div>
        <schedule-day
          v-for="day of visibleDays"
          :key="'schedule-day' + day.date"
          :current-day="day"
          :machines="allMachines"
          :machines-groups="allMachinesGroups"
          :schedule-data="scheduleDays" />
      </div>
    </d-card>
  </d-container>
</template>
<script>
import Vue from 'vue';
import debounce from 'lodash.debounce';
import ListUtils from '@/utils/ListUtils.js';
import FormUtils from '@/utils/FormUtils.js';
import CalendarUtils from '@/utils/CalendarUtils';
import HasFilters from '@/mixins/HasFilters.vue';
import ScheduleDay from '@/components/forms/schedule/ScheduleDay.vue';

export default {
  name: 'schedule3',
  mixins: [
    HasFilters
  ],
  components: {
    'schedule-day': ScheduleDay
  },
  computed: {
    allDataLoaded () {
      return this.loaded && this.loadedMachinesGroups && this.loadedMachines && this.isDataGenerated;
    },
    formattedMonthYear() {
      return (CalendarUtils.getMonthName(this.filters.month) + ' ' + this.filters.year);
    },
    visibleDays () {
      return CalendarUtils.getDaysList(this.filters.year, this.filters.month);
    },
  },
  data () {
    return {
      allMachines: [],
      allMachinesGroups: [],
      currentDate: {
        month: null,
        year: null
      },
      filters: {
        month: null,
        year: null
      },
      isDataGenerated: true,
      loaded: false,
      loadedMachines: false,
      loadedMachinesGroups: false,
      loadError: false,
      monthData: [],
      scheduleDays: []
    };
  },
  mounted () {
    let dateNow = new Date();
    Vue.set(this.currentDate, 'year', dateNow.getFullYear());
    Vue.set(this.currentDate, 'month', dateNow.getMonth() + 1);

    Vue.set(this.filters, 'year', dateNow.getFullYear());
    Vue.set(this.filters, 'month', dateNow.getMonth() + 1);


    // tmp
    // Vue.set(this.filters, 'month', dateNow.getMonth());

    this.loadFilteredData();
    this.loadAdditionalData();
  },
  methods: {
    debouncedLoadFilteredData: debounce(function () {
      this.$bus.$emit('view-filters-save');
      this.loadFilteredData();
    }, 250),
    loadFilteredData () {
      ListUtils.loadItemsData(this, {
        method: 'get',
        endpoint: '/api/orders/schedule/v2/calendar',
        listField: 'scheduleDays',
        pagination: false,
        params: this.filters
      });
    },
    loadAdditionalData () {
      FormUtils.loadAdditionalData(this, {
        endpoint: '/api/machines/groups/items',
        method: 'get',
        outputKey: 'allMachinesGroups',
        loadedKey: 'loadedMachinesGroups',
        errorKey: 'loadError',
        params: {
          pagination: 0
        }
      });
      FormUtils.loadAdditionalData(this, {
        endpoint: '/api/machines/items',
        method: 'get',
        outputKey: 'allMachines',
        loadedKey: 'loadedMachines',
        errorKey: 'loadError',
        params: {
          pagination: 0
        }
      });
    },
    changeMonth(action = null) {
      // action = 'prev' | 'next' | null
      let newMonth = this.filters.month;
      let newYear = this.filters.year;

      if (!action) {
        newMonth = this.currentDate.month;
        newYear = this.currentDate.year;
      } else if (action === 'prev') {
        newMonth -= 1;

        if (newMonth < 1) {
          newMonth = 12;
          newYear -= 1;
        }
      } else if (action === 'next') {
        newMonth += 1;

        if (newMonth > 12) {
          newMonth = 1;
          newYear += 1;
        }
      };

      this.filters.month = newMonth;
      this.filters.year = newYear;

      this.debouncedLoadFilteredData();
    },
    getDayName (dayNumber) {
      return CalendarUtils.getDayName(dayNumber);
    }
  }
}
</script>

<style lang="scss">
.schedule-days-wrapper {
  display: grid;
  font-size: 14px;
  grid-template-columns: repeat(7, 1fr);
  overflow: auto;
}

.schedule-day {
  display: flex;
  flex-direction: column;

  &.different-month {
    opacity: 0.5;
  }

  &:nth-child(7n + 2), /* Wtorki */
  &:nth-child(7n + 4) { /* Czwartki */
    background-color: rgba(190, 229, 232, 0.25) !important;

    .schedule-day-header {
      background-color: rgba(190, 229, 232, 0.25) !important;
    }
  }

  &:nth-child(7n + 6), /* Soboty */
  &:nth-child(7n + 0) { /* Niedziele */
    background-color: rgba(23, 198, 113, 0.25) !important;

    .schedule-day-header {
      background-color: rgba(23, 198, 113, 0.25) !important;
    }
  }

  &.schedule-header {
    min-height: unset;
  }

  &-header {
    display: flex;
    font-weight: bold;
    gap: 15px;
  }

  &-body {
    display: flex;
    flex-direction: column;
    min-height: 37px;
  }

  &-footer {
    display: flex;
    font-weight: bold;
    gap: 15px;
    margin-top: auto;
  }

  &-entry {
    display: flex;
    gap: 15px;

    .col-name {
      line-height: 1.2;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .col-name {
    margin-bottom: auto;
    margin-top: auto;
    width: 230px;

    &:first-child {
      padding-left: 10px;
      width: 240px;
    }
  }

  .col-quantity {
    flex-shrink: 0;
    margin-bottom: auto;
    margin-left: auto;
    margin-top: auto;
    width: 60px;
  }

  .col-machine {
    flex-shrink: 0;
    margin-bottom: auto;
    margin-left: auto;
    margin-top: auto;
    width: 80px;

    &:last-child {
      padding-right: 10px;
      width: 90px;
    }
  }
}
</style>
